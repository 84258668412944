import './styles/global.css';
import './styles/normalize.css';
import 'antd/dist/antd.css';
import 'moment/locale/ru';
import { CookiesProvider } from 'react-cookie';
import * as React from 'react';

import { ChakraProvider } from '@chakra-ui/react';

import { AppRouter } from '../pages';
import { StoreProvider } from '../stores';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

import moment from 'moment';

moment.locale('ru');

const firebaseConfig = {
  apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
  authDomain: 'missis-laser.firebaseapp.com',
  projectId: 'missis-laser',
  storageBucket: 'missis-laser.appspot.com',
  messagingSenderId: '514174044584',
  appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
  measurementId: 'G-QTNN569ZLV',
};

export const App = () => {
  React.useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'session_start');
  }, []);

  /*  window.location.assign('https://referal.missis-laser.ru/');
  return <></>; */

  return (
    <ChakraProvider>
      <StoreProvider>
        <CookiesProvider>
          <AppRouter />
        </CookiesProvider>
      </StoreProvider>
    </ChakraProvider>
  );
};
