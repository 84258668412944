import React from "react";

import { Box } from "@chakra-ui/layout";
import { Divider, Typography } from "antd";

export const PreparationPage = () => {
  return (
    <Box
      style={{
        backgroundImage: `url(/bg.jpg)`,
        backgroundSize: "cover",
        width: "100vw",
        minHeight: "100vh",
        padding: "16px",
      }}
    >
      <Typography style={{ fontSize: 20, fontWeight: 600, textAlign: "center" }}>
        Как подготовиться к процедуре?
      </Typography>
      <Divider />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► НЕ загорать за 14 дней до и 14 дней после процедуры;
      </Typography>
      <Divider style={{ margin: "8px" }} />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► НЕ наносить за 3 дня до процедуры на эпилируемый участок кожи спиртосодержащие средства;
      </Typography>
      <Divider style={{ margin: "8px" }} />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► НЕ принимать антибиотики тетрациклинового ряда и медикаменты, относящиеся к фторхинолонам за 2 недели до
        лазерной эпиляции;
      </Typography>
      <Divider style={{ margin: "8px" }} />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► ЗА месяц до лазерной эпиляции исключить все другие виды эпиляции, КРОМЕ БРИТЬЯ;
      </Typography>
      <Divider style={{ margin: "8px" }} />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► ЗА день (либо день в день, если рост волос интенсивный) ДО лазерной эпиляции НЕОБХОДИМО ТЩАТЕЛЬНО ВЫБРИТЬ
        будущую зону эпиляции (бритва, крем для депиляции, триммер – во время курса прохождения ЛЭ волосы удалять только
        такими методами);
      </Typography>
      <Divider style={{ margin: "8px" }} />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► Также не рекомендуется наносить крема, масла, дезодоранты и спиртосодержащие вещества;
      </Typography>
      <Divider style={{ margin: "8px" }} />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► В день процедуры, если эпилируем лицо, нельзя наносить тональный крем и помаду;
      </Typography>
      <Divider style={{ margin: "8px" }} />
      <Typography style={{ marginTop: "8px", backgroundColor: "#e2e4e2ad", borderRadius: 8, padding: 8 }}>
        ► Пилинги рекомендуем НЕ делать за 10 дней до процедуры.
      </Typography>
      <Typography style={{ padding: 16 }}></Typography>
    </Box>
  );
};

/* 
<Layout style={{ height: "100vh", backgroundImage: `url(/bg.jpg)`, backgroundSize: "cover", width: "100vw" }}>
      <OuterContainer>
        <Paper>
          <ContentContainer>
            <Box p={5}>
              <HeadingStyled textAlign='center'>{headerTitle}</HeadingStyled>
              <StatStyled>
                <StatLabel style={{ textAlign: "center", fontSize: 24 }}>{headerBody}</StatLabel>
              </StatStyled>
            </Box>
            <Box display='flex' flexDirection='column' flexGrow={1} height='0'>
              <Divider />
              {children}
            </Box>
            <Navigations />
          </ContentContainer>
        </Paper>
      </OuterContainer>
    </Layout>
*/
