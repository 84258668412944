import React from "react";
import { FaInstagram } from "react-icons/fa";

import { Box } from "@chakra-ui/layout";
import { Button as ChakraButton } from "@chakra-ui/react";
import { Result } from "antd";

export const ErrorPage = () => {
  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='error'
        title='Произошла ошибка'
        subTitle='Вернитесь и попробуйте еще раз'
        extra={
          <div style={{ marginTop: 16 }}>
            <a href='https://www.instagram.com/missis_laser/' target='_blank' rel='noreferrer'>
              <ChakraButton size='xs' colorScheme='green' leftIcon={<FaInstagram />}>
                Instagram
              </ChakraButton>
            </a>
          </div>
        }
      />
    </Box>
  );
};
